import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../public/img/toplogo_bottom.png'
import _imports_1 from '../../../public/img/home_wechat_black.png'
import _imports_2 from '../../../public/img/home_phone.png'


const _hoisted_1 = { class: "marke-index" }
const _hoisted_2 = {
  key: 0,
  class: "overlay"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "custom-indicator",
  style: {"left":"16.6rem"}
}
const _hoisted_6 = { class: "footer-bottom" }

import { ref, reactive } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const goWayToLink = () => {
  window.location.href = "https://work.weixin.qq.com/kfid/kfc2bc3c0f191885b31";
};

const activeIndex = ref(0); // 默认选中第一个 div
const list = reactive([
  "fendanyun_01",
  "fendanyun_02",
  "fendanyun_03",
  "fendanyun_04",
  "fendanyun_05",
  "fendanyun_06",
  "fendanyun_07",
]);
const initialDivs = ["div1", "div2s", "div3s", "div4s", "div5s", "div6s"]; 
const displayedDivs = ref(initialDivs); 

const setActive = (index, boxItem) => {
  if (activeIndex.value === index) {
    return;
  }
  // 切换当前点击的 boxItem 的状态
  const updatedDivs = displayedDivs.value.map((item, i) => {
    if (i === index) {
      return item.endsWith("s") ? item.slice(0, -1) : item + "s"; // 切换状态
    }
    return item.endsWith("s") ? item : item + "s"; // 其他元素恢复为带 's' 后缀
  });

  displayedDivs.value = updatedDivs; // 更新显示的 div
  activeIndex.value = index; // 更新激活的 div 索引
};

return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "home-nav" }, [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        })
      ])
    ], -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("div", {
          class: "banner-img",
          style: _normalizeStyle({
          backgroundImage: `url(${require(`../../../public/img/${item}.jpg`)})`,
        })
        }, [
          (index === 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(displayedDivs.value, (boxItem, boxIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: boxIndex,
                    class: _normalizeClass(["box", { active: activeIndex.value === boxIndex }]),
                    onClick: ($event: any) => (setActive(boxIndex, boxItem)),
                    style: _normalizeStyle({ width: activeIndex.value === boxIndex ? '45.5%' : '20%' })
                  }, [
                    _createElementVNode("img", {
                      src: require(`../../../public/img/${boxItem}.png`),
                      alt: "Image",
                      class: "box-image"
                    }, null, 8, _hoisted_4)
                  ], 14, _hoisted_3))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 4)
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          onClick: goWayToLink,
          class: "left"
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            style: {"width":"14%","margin":"0 0.8rem"},
            src: _imports_1,
            alt: ""
          }, null, -1),
          _createElementVNode("div", {
            class: "f-xian",
            style: {"width":"6rem"}
          }, "微信在线咨询", -1)
        ])),
        _createVNode(_component_el_popover, {
          placement: "top-start",
          width: "200",
          trigger: "hover"
        }, {
          reference: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "right" }, [
              _createElementVNode("div", { class: "right-mask" }),
              _createElementVNode("div", { class: "right-item" }, [
                _createElementVNode("img", {
                  style: {"width":"14%","margin":"0 0.8rem"},
                  src: _imports_2,
                  alt: ""
                }),
                _createElementVNode("div", {
                  class: "f-xian",
                  style: {"width":"4rem","color":"#fff"}
                }, " 电话咨询 ")
              ])
            ], -1)
          ])),
          default: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("div", null, [
              _createElementVNode("div", { style: {"font-size":"0.6rem","font-weight":"600"} }, "免费咨询电话"),
              _createElementVNode("div", { style: {"color":"#165fd8","font-weight":"600"} }, "400-088-0901")
            ], -1))
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})